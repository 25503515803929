exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bill-ease-js": () => import("./../../../src/pages/BillEase.js" /* webpackChunkName: "component---src-pages-bill-ease-js" */),
  "component---src-pages-birthdayblowout-js": () => import("./../../../src/pages/birthdayblowout.js" /* webpackChunkName: "component---src-pages-birthdayblowout-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-claims-js": () => import("./../../../src/pages/claims.js" /* webpackChunkName: "component---src-pages-claims-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-e-wonline-js": () => import("./../../../src/pages/EWonline.js" /* webpackChunkName: "component---src-pages-e-wonline-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-home-brands-ew-js": () => import("./../../../src/pages/home/brands-ew.js" /* webpackChunkName: "component---src-pages-home-brands-ew-js" */),
  "component---src-pages-home-brands-js": () => import("./../../../src/pages/home/brands.js" /* webpackChunkName: "component---src-pages-home-brands-js" */),
  "component---src-pages-home-extended-warranty-js": () => import("./../../../src/pages/home/extended-warranty.js" /* webpackChunkName: "component---src-pages-home-extended-warranty-js" */),
  "component---src-pages-home-header-carousel-js": () => import("./../../../src/pages/home/header-carousel.js" /* webpackChunkName: "component---src-pages-home-header-carousel-js" */),
  "component---src-pages-home-items-covered-js": () => import("./../../../src/pages/home/items-covered.js" /* webpackChunkName: "component---src-pages-home-items-covered-js" */),
  "component---src-pages-home-lex-figures-js": () => import("./../../../src/pages/home/lex-figures.js" /* webpackChunkName: "component---src-pages-home-lex-figures-js" */),
  "component---src-pages-home-mobile-carousel-js": () => import("./../../../src/pages/home/mobile-carousel.js" /* webpackChunkName: "component---src-pages-home-mobile-carousel-js" */),
  "component---src-pages-home-partner-stores-js": () => import("./../../../src/pages/home/partner-stores.js" /* webpackChunkName: "component---src-pages-home-partner-stores-js" */),
  "component---src-pages-home-partners-ew-js": () => import("./../../../src/pages/home/partners-ew.js" /* webpackChunkName: "component---src-pages-home-partners-ew-js" */),
  "component---src-pages-home-testimonials-js": () => import("./../../../src/pages/home/testimonials.js" /* webpackChunkName: "component---src-pages-home-testimonials-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-le-xciting-treat-js": () => import("./../../../src/pages/LEXcitingTreat.js" /* webpackChunkName: "component---src-pages-le-xciting-treat-js" */),
  "component---src-pages-my-contract-js": () => import("./../../../src/pages/MyContract.js" /* webpackChunkName: "component---src-pages-my-contract-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-promo-js": () => import("./../../../src/pages/promo.js" /* webpackChunkName: "component---src-pages-promo-js" */),
  "component---src-pages-promos-js": () => import("./../../../src/pages/promos.js" /* webpackChunkName: "component---src-pages-promos-js" */),
  "component---src-pages-promos-lex-holidays-worry-free-js": () => import("./../../../src/pages/promos/lex-holidays-worry-free.js" /* webpackChunkName: "component---src-pages-promos-lex-holidays-worry-free-js" */),
  "component---src-pages-promos-power-up-bundle-js": () => import("./../../../src/pages/promos/power_up_bundle.js" /* webpackChunkName: "component---src-pages-promos-power-up-bundle-js" */),
  "component---src-pages-stories-3-reasons-why-you-need-lex-services-js": () => import("./../../../src/pages/stories/3-reasons-why-you-need-lex-services.js" /* webpackChunkName: "component---src-pages-stories-3-reasons-why-you-need-lex-services-js" */),
  "component---src-pages-stories-5-budget-friendly-appliances-this-summer-js": () => import("./../../../src/pages/stories/5-budget-friendly-appliances-this-summer.js" /* webpackChunkName: "component---src-pages-stories-5-budget-friendly-appliances-this-summer-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-tncs-js": () => import("./../../../src/pages/tncs.js" /* webpackChunkName: "component---src-pages-tncs-js" */)
}

